import React from "react";
import { Link } from "gatsby";

import Button from "~/components/button";

import MainLayout from "~/layouts/main";
import SEO from '~/components/seo';
import { H1 } from '~/components/Typography';

const Index: React.FC = () => {
  return (
    <MainLayout>
      <SEO title="404 Page Not found" />
      <div className="px-3 py-3 md:px-5 lg:px-10">
        <H1 underline>Page Not Found</H1>
        <p className="mt-5">The page you're looking for doesn't exist.</p>
        <Link to="/">
          <Button
            action="primary"
            className="mt-5"
            color="purple"
            title="Home"
          >
            <i className="fas fa-home" />&nbsp;Go home
          </Button>
        </Link>
      </div>
    </MainLayout>
  );
};

export default Index;
